import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field } from "@atoms";
import { TemplePattern } from "@molecules";
import { AppLink } from "@base";

const ThankYouPage = ({ data }) => {
  const { heading, copy } = data;
  return (
    <PageContainer showNewsletter hidePhotos background="bg-teal">
      <div className="bg-teal relative flex-grow flex flex-col pt-24 item-center justify-center text-white font-serif">
        <Container variant="xs">
          <div className="bg-black-alpha mt-6 p-6 lg:p-12 flex flex-col text-center items-center border border-yellow justify-center">
            {/* heading */}
            <div className="text-center">
              <Text variant="h1" className="text-yellow font-serif">
                {heading}
              </Text>
              <Text variant="lg" className="mt-3 md:mt-6">
                {copy}
              </Text>
            </div>
          </div>
        </Container>
        {/* <TemplePattern /> */}
      </div>
    </PageContainer>
  );
};

ThankYouPage.defaultProps = {};

ThankYouPage.propTypes = {};

export default ThankYouPage;
