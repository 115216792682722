import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field } from "@atoms";
import {
  TemplePattern,
  HeadingWithCopy,
  Newsletter,
  ImageRight,
  TimelineCard,
} from "@molecules";
import { Hero, ReservationsVisit, ReopeningCovid } from "@organisms";
import { AppLink } from "@base";

const CovidPage = ({ data }) => {
  const { hero, policy, reopening, meta } = { ...data };
  return (
    <PageContainer showNewsletter meta={meta}>
      <Hero {...hero} color="rose" templePattern />

      <section className="bg-offwhite pt-10 pb-14">
        {policy?.map(row => (
          <ImageRight {...row}>
            <Text variant="h2" className="text-teal mb-4">
              {row?.heading}
            </Text>

            <Text
              variant="body"
              className="text-bold text-lg text-blue max-w-sm w-full leading-tight md:leading-tight"
            >
              {row?.content}
            </Text>
          </ImageRight>
        ))}
      </section>

      <section className="bg-teal pt-10 pb-14">
        <ReopeningCovid {...reopening} />
      </section>
    </PageContainer>
  );
};

CovidPage.defaultProps = {};

CovidPage.propTypes = {};

export default CovidPage;
