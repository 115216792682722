import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field, Image } from "@atoms";
import {
  HeadingWithCopy,
  Newsletter,
  Mandala,
  Filter,
  TemplePattern,
  FeaturedCard,
  UpcomingCard,
  MediaCard,
} from "@molecules";
import { Hero, SupportHome, SearchModal } from "@organisms";
import { AppLink } from "@base";

const TeachingsPage = ({ data }) => {
  const { hero, filters, featured, teachings, button, meta } = {
    filters: [
      {
        text: "Articles",
        slug: "articles",
      },
      {
        text: "Classes",
        slug: "classes",
      },
      {
        text: "Podcasts",
        slug: "podcasts",
      },
      {
        text: "Audio Teachings",
        slug: "audioTeachings",
      },
      {
        text: "Videos",
        slug: "videos",
      },
    ],
    button: "View More",
    ...data,
  };
  const [activeFilter, setFilter] = useState("all");
  const [visible, setVisible] = useState(4);
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <PageContainer meta={meta}>
      <Hero {...hero} mandalaPattern />

      <section className="bg-rose pt-4 pb-32 relative">
        <Container variant="lg" className="relative z-50">
          <div className="flex flex-col md:flex-row border-b border-yellow">
            <div className="md:flex justify-between w-full">
              <ul className="flex flex-wrap items-center mr-6 md:flex-shrink-0 flex-row -mx-2">
                {filters?.map((filter, i) => {
                  return (
                    <li className="px-2 mb-4">
                      <Filter
                        {...filter}
                        key={i}
                        active={activeFilter === filter?.slug}
                        onClick={() => {
                          setFilter(filter?.slug);
                        }}
                      />
                    </li>
                  );
                })}
                <li className="px-2 mb-4">
                  <Filter
                    text="All"
                    slug="all"
                    active={activeFilter === "all"}
                    onClick={() => {
                      setFilter("all");
                    }}
                  />
                </li>
              </ul>
              <button
                type="button"
                className="bg-white-translucent flex max-w-xs items-center w-full justify-between flex-grow font-bold font-serif text-left px-3 py-1 mb-4 text-offwhite italic font-semibold text-sm"
                onClick={() => setSearchOpen(true)}
              >
                <span>Search Teachings</span>
                <Icon name="search" className="w-3 text-yellow ml-3 h-3" />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-16 w-full">
            <div className="col-span-2 max-w-lg w-full">
              {activeFilter === "all" && <FeaturedCard {...featured} />}

              <div className="mt-12">
                {(activeFilter !== "all" ? [featured, ...teachings] : teachings)
                  ?.filter(
                    t => t.type.text === activeFilter || activeFilter === "all"
                  )
                  .slice(0, visible)
                  .map((teaching, i) => {
                    return <MediaCard {...teaching} key={i} dark />;
                  })}
              </div>

              {visible < teachings.length && (
                <div className="mt-8">
                  <Button
                    onClick={() => {
                      setVisible(visible + 5);
                    }}
                    size="md"
                    color="transparent"
                  >
                    <Text className="font-serif italic text-offwhite">
                      View More
                    </Text>
                  </Button>
                </div>
              )}
            </div>

            <div className="col-span-1 space-y-12 relative">
              <div className="sticky pt-4 top-0">
                {/* <UpcomingCard {...upcoming} /> */}
                <Newsletter small />
              </div>
            </div>
          </div>
        </Container>

        <TemplePattern />
      </section>
      <div className="bg-teal py-12">
        <SupportHome />
      </div>
      <SearchModal
        content={[featured, ...teachings].map((a, i) => {
          return { ...a, id: i };
        })}
        placeholder="Search Teachings"
        heading="Teachings Search Results"
        openState={[searchOpen, setSearchOpen]}
        resultCard={MediaCard}
        backCopy="Back to Index"
      />
    </PageContainer>
  );
};

export default TeachingsPage;
