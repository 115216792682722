import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field, Image } from "@atoms";
import {
  HeadingWithCopy,
  Newsletter,
  Mandala,
  Filter,
  TemplePattern,
  FeaturedCard,
  UpcomingCard,
  MediaCard,
} from "@molecules";
import { Hero, SearchModal } from "@organisms";
import { AppLink } from "@base";

const EventsPage = ({ data }) => {
  const [activeFilter, setFilter] = useState("all");
  const [visible, setVisible] = useState(4);
  const [searchOpen, setSearchOpen] = useState(false);

  const { hero, filters, featured, upcoming, events, meta } = {
    filters: [
      {
        text: "Special Events",
        slug: "specialEvents",
      },
      {
        text: "Recurring Rituals",
        slug: "recurringRituals",
      },
      {
        text: "Talks/Presentations",
        slug: "talksPresentations",
      },
      {
        text: "Performance Arts",
        slug: "performanceArts",
      },
    ],
    ...data,
  };
  return (
    <PageContainer showNewsletter meta={meta}>
      <Hero {...hero} mandalaPattern />

      <section className="bg-teal pt-4 pb-32 relative">
        <Container variant="lg" className="relative z-50">
          <div className="flex flex-col md:flex-row border-b border-yellow">
            <div className="md:flex justify-between w-full">
              <ul className="flex flex-wrap items-center mr-6 md:flex-shrink-0 flex-row -mx-2">
                {filters?.map((filter, i) => {
                  return (
                    <li className="px-2 mb-4">
                      <Filter
                        {...filter}
                        key={i}
                        active={activeFilter === filter?.slug}
                        onClick={() => {
                          setFilter(filter?.slug);
                        }}
                      />
                    </li>
                  );
                })}
                <li className="px-2 mb-4">
                  <Filter
                    text="All"
                    slug="all"
                    active={activeFilter === "all"}
                    onClick={() => {
                      setFilter("all");
                    }}
                  />
                </li>
              </ul>
              <button
                type="button"
                className="bg-white-translucent flex-grow flex max-w-xs items-center w-full justify-between font-bold font-serif text-left px-3 py-1 mb-4 text-offwhite italic font-semibold text-sm"
                onClick={() => setSearchOpen(true)}
              >
                <span>
                  Search<span className="hidden lg:inline"> Events</span>
                </span>
                <Icon name="search" className="w-3 text-yellow ml-3 h-3" />
              </button>
            </div>
          </div>

          <div className="grid grid-col-1 md:grid-cols-3 gap-x-16 w-full">
            <div className="col-span-2 max-w-lg w-full">
              {activeFilter === "all" && <FeaturedCard {...featured} />}

              <div className="mt-12">
                {(activeFilter !== "all" ? [featured, ...events] : events)
                  ?.filter(e => {
                    return (
                      e.type.text === activeFilter || activeFilter === "all"
                    );
                  })
                  .slice(0, visible)
                  .map((event, i) => {
                    return <MediaCard {...event} key={i} dark />;
                  })}
              </div>

              {visible < events.length && (
                <div className="mt-8">
                  <Button
                    onClick={() => {
                      setVisible(visible + 5);
                    }}
                    size="md"
                    color="transparent"
                  >
                    <Text className="font-serif italic text-offwhite">
                      View More
                    </Text>
                  </Button>
                </div>
              )}
            </div>

            <div className="col-span-1">
              <div className="pt-4 sticky top-0 space-y-12">
                <UpcomingCard {...upcoming} />
                <Newsletter small />
              </div>
            </div>
          </div>
        </Container>

        <TemplePattern />
      </section>
      <SearchModal
        content={[featured, ...events].map((a, i) => {
          return { ...a, id: i };
        })}
        placeholder="Search Events"
        heading="Events Search Results"
        openState={[searchOpen, setSearchOpen]}
        resultCard={MediaCard}
        backCopy="Back to Index"
      />
    </PageContainer>
  );
};

export default EventsPage;
