import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field, Image } from "@atoms";
import { HeadingWithCopy, Newsletter, Carousel, Mandala } from "@molecules";
import { Hero, SriBabaAbout, RelatedActivitiesAbout } from "@organisms";
import { AppLink } from "@base";

const AboutPage = ({ data }) => {
  const { hero, history, carousel, about, grid, related, meta } = {
    ...data,
  };

  return (
    <PageContainer showNewsletter meta={meta}>
      <Hero {...hero} mandalaPattern />

      <section className="bg-offwhite pt-10 pb-14">
        <HeadingWithCopy
          heading={history?.heading}
          headingColor="teal"
          iconColor="orange"
          className="text-4xl md:text-6xl"
        >
          <Container
            variant="sm"
            className="columns-1 lg:columns-2 lg:gap-x-16 xl:gap-x-32 space-y-4 mb-8"
          >
            <Text variant="body" className="text-left">
              {history?.content}
            </Text>
          </Container>
        </HeadingWithCopy>

        <Container variant="xs">
          <Carousel showIndicators centerItems>
            {carousel?.map((card, i) => {
              return (
                <div className="w-full">
                  <Image
                    {...card.image}
                    key={i}
                    fit="contain"
                    transparent
                    fitHeight
                    placeholder={false}
                  />
                </div>
              );
            })}
          </Carousel>
        </Container>

        <SriBabaAbout {...about} />
      </section>

      <section className="bg-offwhite pt-10">
        <div className="w-full mx-auto">
          <div className="grid place-content-stretch gap-0.5 grid-cols-2 grid-row-3 lg:grid-cols-3 lg:grid-row-2 items-stretch">
            {grid?.map((image, i) => {
              return (
                <div key={i} className="transition-all h-48 md:h-96 w-full">
                  <Image {...image} fill aspectRatio="479:356" />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="bg-teal pt-10 pb-14 relative overflow-x-hidden">
        <Mandala />

        <div className="relative z-10">
          <HeadingWithCopy
            heading={related?.heading}
            headingColor="white"
            iconColor="yellow"
          >
            <Text className="text-offwhite font-serif w-full max-w-md mx-auto leading-snug mb-8">
              {related?.content}
            </Text>
          </HeadingWithCopy>

          <RelatedActivitiesAbout {...related} />
        </div>
      </section>
    </PageContainer>
  );
};

export default AboutPage;
