import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container } from "@atoms";
import {
  HeroCard,
  FullWidth,
  ReservationsHome,
  Newsletter,
  CommunityHome,
  TemplePattern,
  Mandala,
} from "@molecules";
import {
  HeroHome,
  LeftRightHome,
  EventHome,
  SupportHome,
  TeachingsHome,
} from "@organisms";
import { DefaultPageContainer as PageContainer } from "@layout";

const HomePage = ({ data }) => {
  const {
    hero,
    cards,
    fullWidth,
    leftRight,
    reservations,
    homeEvents,
    newsletter,
    community,
    support,
    teaching,
    meta,
  } = { ...data };
  return (
    <PageContainer showSupport showCommunity meta={meta}>
      <div className="relative z-10">
        <HeroHome {...hero} />
      </div>

      <section className="bg-teal relative z-50 overflow-visible">
        <div className="w-full flex flex-col md:flex-row justify-center top-0 -mt-12 md:-top-32 md:-mb-24 relative z-50 py-2 md:py-0">
          {cards?.map((card, i) => (
            <HeroCard {...card} key={i} />
          ))}
        </div>

        <FullWidth {...fullWidth} />

        <Container variant="xl">
          {leftRight?.map((content, i) => (
            <LeftRightHome
              {...content}
              key={i}
              last={i === leftRight.length - 1}
              position={i % 2 === 0 ? "left" : "right"}
            />
          ))}
        </Container>

        <ReservationsHome {...reservations} />
      </section>

      <section className="bg-offwhite pt-10 pb-14">
        <EventHome {...homeEvents} />
      </section>

      <section className="relative px-6 bg-rose pt-14 pb-20 overflow-x-hidden">
        <TemplePattern flip />
        <TeachingsHome {...teaching} />
        <Mandala position="center-left" />
        <Newsletter />
        <TemplePattern />
      </section>
    </PageContainer>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default HomePage;
