import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field, Image } from "@atoms";
import { Newsletter, UpcomingCard, PostContent } from "@molecules";
import { ContentBuilder } from "@organisms";
import { AppLink } from "@base";

const EventPage = ({ data }) => {
  const { post, back, related, newsletter, contentBuilder, url, meta } = {
    ...data,
  };
  // const [activeFilter, setFilter] = useState("all");
  return (
    <PageContainer showNewsletter meta={meta}>
      <div className="bg-teal h-20 w-full" />
      <section className="bg-white text-blue pt-4 pb-32 relative">
        <Container variant="lg" className="relative z-50">
          <div className="grid grid-col-1 md:grid-cols-3 gap-x-16 w-full">
            <div className="col-span-2 max-w-lg w-full pt-6 text-blue">
              <div className="max-w-max">
                <AppLink to={back?.url}>
                  <Text variant="body" className="underline-1 text-rose italic">
                    {back?.text}
                  </Text>
                </AppLink>
              </div>

              <PostContent {...post} />
              <ContentBuilder blocks={contentBuilder} />

              <div className="mt-8 space-x-3 flex">
                <Text variant="h7" className="text-teal font-extrabold">
                  Share:
                </Text>
                <AppLink
                  to={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftemple.mountmadonna.org${url}`}
                  className={classNames(
                    "rounded-full w-6 h-6 transtion duration-500 bg-rose text-white hover:bg-yellow flex items-center justify-center",
                    {}
                  )}
                >
                  <Icon name="facebook" className="w-4 h-4" fitHeight />
                </AppLink>
                {/* <AppLink
        to={`https://twitter.com/intent/tweet?url${url}`}
        className={classNames("", { [color]: true })}
      >
        <Icon name="twitter" className="w-6 h-6" />
      </AppLink> */}
                {/* <AppLink
                  to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
                  className={classNames(
                    "transform hover:scale-105 transtion duration-500 bg-rose text-white hover:bg-yellow",
                    {}
                  )}
                >
                  <Icon name="linkedin" className="w-6 h-6" />
                </AppLink> */}
                <AppLink
                  to={`mailto:?body=https%3A%2F%2Ftemple.mountmadonna.org${url}`}
                  className={classNames(
                    "rounded-full w-6 h-6 transtion duration-500 bg-rose text-white hover:bg-yellow flex items-center justify-center",
                    {}
                  )}
                >
                  <Icon name="email" className="w-4 h-4" fitHeight />
                </AppLink>
              </div>
            </div>

            <div className="col-span-1 pt-10 space-y-12">
              <UpcomingCard {...related} inverse />

              <Newsletter {...newsletter} small color="rose" />
            </div>
          </div>
        </Container>
      </section>
    </PageContainer>
  );
};

export default EventPage;
