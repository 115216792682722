import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field, Video } from "@atoms";
import { TemplePattern, HeadingWithCopy, Newsletter } from "@molecules";
import { Hero, ReservationsVisit } from "@organisms";
import { AppLink } from "@base";

const VisitPage = ({ data }) => {
  const { hero, closed, reservations, mediaUrl, meta } = {
    ...data,
  };
  return (
    <PageContainer showNewsletter meta={meta}>
      <Hero {...hero} color="rose" templePattern="true" />

      <section className="bg-teal pt-10 pb-14">
        <HeadingWithCopy
          headingColor="white"
          iconColor="yellow"
          heading={closed?.heading}
        >
          <Text className="text-offwhite font-serif w-full max-w-xl mx-auto leading-snug mb-2">
            {closed?.content}
          </Text>
          {closed?.links?.map(link => {
            if (link.type === "button") {
              return (
                <div className="mt-6">
                  <Button size="md" color="yellow" to={link.link?.url}>
                    <Text variant="body" className="italic font-serif">
                      {link.link?.text}
                    </Text>
                  </Button>
                </div>
              );
            }
            return (
              <div className="mt-6 flex items-center justify-center">
                <AppLink to={link.link?.url}>
                  <Text
                    variant="body"
                    className="text-offwhite font-serif underline-1--hover italic tracking-wider max-w-max"
                  >
                    {link.link?.text}
                  </Text>
                </AppLink>
              </div>
            );
          })}
        </HeadingWithCopy>
      </section>

      {mediaUrl && (
        <section>
          <Video url={mediaUrl} />
        </section>
      )}

      <section className="bg-offwhite pt-10 pb-14">
        <ReservationsVisit {...reservations} />
      </section>
    </PageContainer>
  );
};

VisitPage.defaultProps = {};

VisitPage.propTypes = {};

export default VisitPage;
